export const Partners = {
  partners: [
	{
		"name": "Abitos",
		"link": "http://abitos.com/"
	},
	{
		"name": "Acción",
		"link": "https://www.accion.org/"
	},
	{
		"name": "ActionCOACH Business Coaching",
		"link": "https://actioncoachteamsage.com/staff-directory/jody-johnson/"
	},
	{
		"name": "Arts & Business Council of Miami",
		"link": "https://www.artsbizmiami.org/"
	},
	{
		"name": "Actualidad Radio",
		"link": "http://actualidadradio.com/"
	},
	{
		"name": "Akerman LLP",
		"link": "www.akerman.com"
	},
	{
		"name": "APEX Brasil - Brazilian Trade and Investment Progromtion Agency",
		"link": "http://www.apexbrasil.com.br/en/home"
	},
	{
		"name": "Argentine American Chamber of Commerce of Florida ",
		"link": "https://argentineamerican.org/"
	},
	{
		"name": "Axis Helps South Florida",
		"link": "https://www.axishelps.org/"
	},
	{
		"name": "BBFI - Black Business Investment Fund",
		"link": "https://bbifflorida.com/"
	},
	{
		"name": "Branches",
		"link": "https://branchesfl.org/"
	},
	{
		"name": "Brazil American Chamber of Commerce of Florida ",
		"link": "https://www.brazilchamber.org/"
	},
	{
		"name": "British American Business Council Miami ",
		"link": "https://babcmiami.org/"
	},
	{
		"name": "Buchanan Ingersoll & Rooney PC",
		"link": "www.bipc.com"
	},
	{
		"name": "CAMACOL - Latin Chamber of Commerce of U.S.A. ",
		"link": "https://camacol.org/"
	},
	{
		"name": "Canada-Florida Chamber of Commerce",
		"link": "https://canadafloridachamber.com/"
	},
	{
		"name": "CareerSource South Florida",
		"link": "http://www.careersourcesfl.com/"
	},
	{
		"name": "Carlton Fields",
		"link": "https://www.carltonfields.com/"
	},
	{
		"name": "Chile-US Chamber of Commerce ",
		"link": "http://www.chileus.org/"
	},
	{
		"name": "City of North Miami",
		"link": "https://www.northmiamifl.gov/"
	},
	{
		"name": "Colombian American Chamber of Commerce ",
		"link": "http://colombiamericancha.wixsite.com/mysite"
	},
	{
		"name": "Consular Corps of Miami",
		"link": "https://www.miamidade.gov/business/international-consulates.asp"
	},
	{
		"name": "Coral Gables Chamber of Commerce",
		"link": "https://coralgableschamber.org/update-page/"
	},
	{
		"name": "Cozen O'Connor",
		"link": "https://www.cozen.com/"
	},
	{
		"name": "DLA Piper",
		"link": "https://www.dlapiper.com/en/us/"
	},
	{
		"name": "Dominican International Business",
		"link": " Tourism & Development Federation"
	},
	{
		"name": "Entercom Communications",
		"link": "https://entercom.com/markets/miami/"
	},
	{
		"name": "European American Chamber of Commerce Florida ",
		"link": "https://eaccfl.com/"
	},
	{
		"name": "Export-Import Bank of the United States",
		"link": "https://www.exim.gov/"
	},
	{
		"name": "Fleitas PLLC",
		"link": "https://www.fleitaslaw.com/"
	},
	{
		"name": "Florida DEO Reemployment Assistance",
		"link": "https://fldeo-flwork-prod1.pegacloud.net/prweb/app/default/tHGV_g6FQZMSYakWUervaQ%28%28*/!STANDARD"
	},
	{
		"name": "Florida Department of Economic Opportunity (DEO)",
		"link": "floridajobs.org"
	},
	{
		"name": "Foley & Lardner LLP",
		"link": "https://www.foley.com/en"
	},
	{
		"name": "French American Chamber of Commerce ",
		"link": "https://www.faccmiami.com/"
	},
	{
		"name": "FSMSDC - Florida State Minority Supplier Development Council",
		"link": "https://fsmsdc.org/"
	},
	{
		"name": "General Assembly",
		"link": "https://generalassemb.ly/"
	},
	{
		"name": "German American Business Chamber of South Florida Inc. ",
		"link": "https://www.gabc.us/Affiliations"
	},
	{
		"name": "Goldman Sachs 10,000 Small Businesses at Miami Dade College",
		"link": "000 Small Businesses at Miami Dade College"
	},
	{
		"name": "Gray Robinson",
		"link": "http://www.gray-robinson.com/offices/miami-fl"
	},
	{
		"name": "Greater Miami Chamber of Commerce",
		"link": "https://www.miamichamber.com/news/covid-19-business-resource-guide"
	},
	{
		"name": "Greater Miami Convention & Visitors Bureau",
		"link": "https://www.miamiandbeaches.com/help"
	},
	{
		"name": "Greenberg Traurig",
		"link": " LLP"
	},
	{
		"name": "Greenspoon Marder",
		"link": "https://www.gmlaw.com/"
	},
	{
		"name": "Guatemala Trade and Investment Office ",
		"link": "(305) 373-0322"
	},
	{
		"name": "Gunster",
		"link": "www.gunster.com"
	},
	{
		"name": "Guyanese American Chamber of Commerce ",
		"link": "https://www.facebook.com/pages/category/Nonprofit-Organization/Guyanese-American-Chamber-of-CommerceInc-485655798303411/"
	},
	{
		"name": "HAACOF - Haitian American Chamber of Commerce of Florida",
		"link": "http://www.haccof.com/"
	},
	{
		"name": "HACCOF - Haitian American Chamber of Commerce of Florida",
		"link": "http://www.haccof.com/"
	},
	{
		"name": "Haitian American Chamber of Commerce of Florida ",
		"link": "http://www.haccof.com/"
	},
	{
		"name": "Holland & Knight ",
		"link": "https://www.hklaw.com/en"
	},
	{
		"name": "Honduras-USA Chamber of Commerce ",
		"link": "http://haccw.org/contact"
	},
	{
		"name": "Hunton Andrews Kurth LLP (formely Hunton & Williams LLP) ",
		"link": "http://www.hunton.com"
	},
	{
		"name": "Indo-U.S. Chamber of Commerce Inc.",
		"link": " South Florida "
	},
	{
		"name": "Italy-America Chamber of Commerce Southeast",
		"link": "www.iacc-miami.com"
	},
	{
		"name": "Jamaica USA Chamber of Commerce",
		"link": "www.jamaicausachamber.org"
	},
	{
		"name": "Legal Services of Greater Miami / Florida Community Development Legal Project",
		"link": "https://www.flcommunitydevelopment.org/ "
	},
	{
		"name": "Lesesne Media Group ",
		"link": "https://www.infocustv.online/"
	},
	{
		"name": "Liberty City Trust",
		"link": "https://www.lctmiami.org/"
	},
	{
		"name": "Marcum Accountants & Advisors",
		"link": "https://www.marcumllp.com/offices/miami-florida"
	},
	{
		"name": "MBAF - Morrison Brown Argiz & Farra ",
		"link": "https://mbafcpa.com/"
	},
	{
		"name": "MBDA - Minority Business Development Agency",
		"link": "https://www.mbda.gov/businesscenters/miami"
	},
	{
		"name": "McGill & Associates MBDA - Minority Business Development Agency",
		"link": "http://www.mgillonline.com/MBDA"
	},
	{
		"name": "M-DCPS - Miami-Dade County Public Schools",
		"link": "http://procurement.dadeschools.net/"
	},
	{
		"name": "Miami Bayside Foundation",
		"link": "www.miamibaysidefoundation.org"
	},
	{
		"name": "Miami DDA - Miami Downtown Development Authority",
		"link": "http://www.miamidda.com/"
	},
	{
		"name": "Miami-Dade Beacon Council",
		"link": "https://www.beaconcouncil.com/solutions/small-business/"
	},
	{
		"name": "Miami-Dade Chamber of Commerce",
		"link": "https://m-dcc.org/"
	},
	{
		"name": "Miami-Dade County",
		"link": "http://www.miamidade.gov/global/home.page"
	},
	{
		"name": "Miami-Dade County Public Schools",
		"link": "http://procurement.dadeschools.net/"
	},
	{
		"name": "Miami-Dade County Small Business Department",
		"link": "http://www.miamidade.gov/smallbusiness/library/flyers/sba-coronavirus-relief.pdf"
	},
	{
		"name": "Miami Herald",
		"link": "https://www.miamiherald.com/"
	},
	{
		"name": "NANA - Neighbors and Neighbors Association",
		"link": "(305) 756-0605"
	},
	{
		"name": "Nelson Mullins",
		"link": "https://www.nelsonmullins.com/"
	},
	{
		"name": "North Miami CRA",
		"link": "http://www.northmiamicra.org/"
	},
	{
		"name": "Opa-locka CDC - Opa-locka Community Development Corporation",
		"link": "http://www.olcdc.org/-"
	},
	{
		"name": "Overtown Connect - powered by Venture Cafe",
		"link": "https://venturecafemiami.org/overtown/"
	},
	{
		"name": "Overtown CRA",
		"link": ""
	},
	{
		"name": "Panamanian American Chamber of Commerce",
		"link": "http://paccmiami.com"
	},
	  {
		"name": "Partners for Self-Employment",
		"link": "http://partnersforselfemployment.org/"
	},
	{
		"name": "Peruvian American Chamber of Commerce",
		"link": "www.peruvianchamber.org"
	},
	{
		"name": "Polsinelli PC",
		"link": "https://www.polsinelli.com/"
	},
	{
		"name": "ProChile ",
		"link": "https://www.prochile.gob.cl/documento-biblioteca/miami/"
	},
	{
		"name": "ProColombia ",
		"link": "https://procolombia.co/en/about-us/office-network/north-america/united-states-of-america"
	},
	{
		"name": "Procomer - Trade Promotion Agency of Costa Rica ",
		"link": "https://www.procomer.com/es/"
	},
	{
		"name": "ProEcuador ",
		"link": "https://www.linkedin.com/company/pro-ecuador/"
	},
	{
		"name": "Prospera",
		"link": "https://prosperausa.org/"
	},
	{
		"name": "Romanian American Chamber of Commerce -Florida Chapter",
		"link": "www.racc.ro"
	},
	{
		"name": "Sant-La",
		"link": "https://www.santla.org/"
	},
	{
		"name": "SBDC at FIU- Small Business Development Center at FIU",
		"link": "https://business.fiu.edu/centers/sbdc/"
	},
	{
		"name": "Slalom",
		"link": "https://www.slalom.com/"
	},
	{
		"name": "SOBEWFF® & FIU Chaplin School Hospitality Industry Relief Fund",
		"link": "https://sobewff.org/about/fiu/"
	},
	{
		"name": "South Florida Digital Alliance",
		"link": "http://sfdigitalalliance.org/"
	},
	{
		"name": "South Florida Irish American Chamber of Commerce",
		"link": "https://sfiacc.org/"
	},
	{
		"name": "South Florida Regional Planning Council",
		"link": "http://sfregionalcouncil.org/"
	},
	{
		"name": "South Flroida Hispanic Chamber of Commerce ",
		"link": "www.sflhcc.com"
	},
	{
		"name": "Spain-U.S. Chamber of Commerce",
		"link": "http://spainuschamber.com/ed/"
	},
	{
		"name": "Swedish-American Chamer of Commerce -Florida",
		"link": "www.sacc-florida.com"
	},
	{
		"name": "Taiwan Trade Center Miami",
		"link": "http://miami.taiwantrade.com.tw/"
	},
	{
		"name": "The Business Forum Group at Doral",
		"link": "http://bizforumgroup.com/"
	},
	{
		"name": "The Victoria Law Group",
		"link": "https://www.thevictorialawgroup.com/"
	},
	  {
		"name": "Tools for Change",
		"link": "https://tfcmiami.org/"
	},
	{
		"name": "Trade Commisoner of Canada ",
		"link": "https://www.tradecommissioner.gc.ca/united-states-of-america-etats-unis-amerique/office-bureau/index.aspx?office=MIAMI&lang=eng"
	},
	{
		"name": "Trade Commisoner of Spain in Miami ",
		"link": "https://www.icex.es/icex/es/navegacion-principal/todos-nuestros-servicios/programas-y-servicios-de-apoyo/centros-de-negocio/CNG2014349470.html"
	},
	{
		"name": "U.S. - Mexico Chamber of Commerce Inter-American Chapter ",
		"link": "www.usmcocfl.org"
	},
	{
		"name": "US Small Business Association",
		"link": "https://www.sba.gov/offices/district/fl/miami"
	},
	{
		"name": "Venezuelan-American Chamber of Commerce of the United Staes Inc. ",
		"link": "www.venezuelachamber.org"
	},
	{
		"name": "Wilson Elser Moskowitz Edelman & Dicker LLP",
		"link": "https://www.wilsonelser.com/"
	},
	{
		"name": "YWCA Greater Miami-Dade Inc. - Services for Women",
		"link": "https://www.ywca-miami.org/"
	}
],
}

export default Partners
